import { createSlice } from "@reduxjs/toolkit";

const initialNotificationState = {
  notificationOpen: false,
  notificationType: "",
  notificationMessage: "",
  showDeliveryWarning: false,
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: initialNotificationState,
  reducers: {
    showNotification: (state, action) => {
      // return action.payload;
      const { payload } = action;
      state.notificationOpen = true;
      state.notificationType = payload.notificationType;
      state.notificationMessage = payload.notificationMessage;
    },
    closeNotification: (state, action) => {
      return initialNotificationState;
    },
    changeShowDeliveryWarning: (state, action) => {
      return { ...state, showDeliveryWarning: action.payload };
    },
  },
});

export const {
  showNotification,
  closeNotification,
  changeShowDeliveryWarning,
} = notificationSlice.actions;

export default notificationSlice.reducer;
