import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineShopping } from "react-icons/ai";
import classes from "./NavBar.module.css";
import { useSelector } from "react-redux";
import { CartSideMenu } from "../cart-side-menu/CartSideMenu";
import mockLogo from "../../assets/images/FlytieLogo.png";

export const NavBar = () => {
  const products = useSelector((state) => state.products.items);
  const [cartIsOpen, setCartIsOpen] = useState(false);
  const [animate, setAnimate] = useState(false);

  const removeDuplicatesArray = () => {
    const arrFilt = products.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    return arrFilt;
  };

  const handleCartClose = () => {
    setAnimate(false);
    setTimeout(() => {
      setCartIsOpen(false);
    }, 220);
  };

  const handleCartOpen = () => {
    setAnimate(true);
    setCartIsOpen(true);
  };

  const navLinkStyle = (isActive) => {
    return {
      color: isActive && "rgb(41, 153, 41)",
    };
  };

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <NavLink to='/'>
          <img src={mockLogo} className={classes.mockLogo} alt='logo' />
        </NavLink>
        <NavLink
          exact='true'
          to='/'
          style={({ isActive }) => navLinkStyle(isActive)}
        >
          Sākums
        </NavLink>
        <NavLink
          exact='true'
          to='/shop'
          style={({ isActive }) => navLinkStyle(isActive)}
        >
          Veikals
        </NavLink>
        <NavLink
          exact='true'
          to='/contacts'
          style={({ isActive }) => navLinkStyle(isActive)}
        >
          Kontakti
        </NavLink>
      </div>
      <div
        className={classes.cartIconContainer}
        onMouseEnter={() => handleCartOpen()}
        onMouseLeave={() => handleCartClose()}
      >
        <NavLink
          to='/cart'
          className={classes.cartIconLink}
          onClick={handleCartClose}
        >
          <AiOutlineShopping className={classes.cartIcon} />
          {products?.length > 0 && (
            <div className={classes.productCount}>{products.length}</div>
          )}
        </NavLink>
        <div
          className={`
                ${classes.navBarCartContainer} 
                ${animate ? classes.animateOpen : classes.animateClose}
              `}
          style={{ visibility: cartIsOpen ? "visible" : "hidden" }}
          onClick={handleCartClose}
        >
          <CartSideMenu
            filteredCart={removeDuplicatesArray()}
            cart={products}
            handleCartClose={() => handleCartClose()}
          />
        </div>
      </div>
    </div>
  );
};
