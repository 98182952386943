import { createSlice } from "@reduxjs/toolkit";

const localStorageProducts = JSON.parse(localStorage.getItem("productCart"));
const initialProducts =
  localStorageProducts?.length > 0 ? localStorageProducts : [];

export const counterSlice = createSlice({
  name: "products",
  initialState: { items: initialProducts, checkoutModal: false },
  reducers: {
    itemAdded: (state, action) => {
      state.items.push(action.payload);
    },
    itemRemoved: (state, action) => {
      const indexOfObject = state.items.findIndex((object) => {
        return object.id === action.payload;
      });
      indexOfObject !== -1 && state.items.splice(indexOfObject, 1);
    },
    allItemsRemoved: (state, action) => {
      const filteredState = state.items.filter((object) => {
        return object.id !== action.payload;
      });
      state.items.splice(0, state.items.length, ...filteredState);
    },
    toggleCheckout: (state, action) => {
      return { ...state, checkoutModal: !state.checkoutModal };
    },
    emptyBasket: (state, action) => {
      return { items: [], checkoutModal: false }
    },
  },
});

// Action creators are generated for each case reducer function
export const { itemAdded, itemRemoved, allItemsRemoved, toggleCheckout, emptyBasket } =
  counterSlice.actions;

export default counterSlice.reducer;
