import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import posthog from "posthog-js";
import { Amplify, API } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);
API.configure(awsconfig);
posthog.init("phc_l4KtLI7lAIXLOfnuXygKW8rpftgWtie5V91pivBjNab", {
  api_host: "https://eu.posthog.com",
});

// import { Amplify } from "aws-amplify";
// import config from "./aws-config";
// Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
