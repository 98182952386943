/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://37vcdtg4tzf67hywniz6dv7ayy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-peo64ydo6zcz5k5f2kenktbaou",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeapi",
            "endpoint": "https://y0oycw8bp7.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "flytieonlinestore718a7036e344492cb4f5d73f134c7c153039-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
