export const totalProductPrice = (products) => {
  const allPrices = [];
  products.forEach((element) => {
    allPrices.push(element.price);
  });
  const total = allPrices.reduce((partialSum, a) => partialSum + a, 0);

  const depositProductCount = products.filter(
    (product) => !!product.glass_deposit
  ).length;
  const depositPrice = depositProductCount * 0.1;

  return (total + depositPrice).toFixed(2);
};

export const deliveryPrice = (deliveryOptionName) => {
  return deliveryOptionName === "Omniva pakomāts" ? 3.5 : 0;
};

export const totalPrice = (products, deliveryOptionName) => {
  const allPrices = [];
  products.forEach((element) => {
    allPrices.push(element.price);
  });
  const total = allPrices.reduce((partialSum, a) => partialSum + a, 0);
  const totalWithDelivery =
    deliveryOptionName === "Omniva pakomāts" ? Number(total) + 3.5 : total;

  const depositProductCount = products.filter(
    (product) => !!product.glass_deposit
  ).length;
  const depositPrice = depositProductCount * 0.1;

  return (totalWithDelivery + depositPrice).toFixed(2);
};
