import cn from "./Footer.module.css";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <div className={cn.footerContainer}>
      <div className={cn.navigation}>
        <NavLink className={cn.footerLink} to='/'>
          Sākums
        </NavLink>
        <NavLink className={cn.footerLink} to='/shop'>
          Veikals
        </NavLink>
        <NavLink className={cn.footerLink} to='/contacts'>
          Kontakti
        </NavLink>
      </div>
      <div className={cn.footerSubContainer}>
        <div>Seko mums</div>
        <a href='https://www.facebook.com/profile.php?id=61555052858784'>
          <FaFacebookF className={cn.footerIcon} />
        </a>
        <a href='https://www.instagram.com/flytie_shop/'>
          <FaInstagram className={cn.footerIcon} />
        </a>
      </div>
      <NavLink className={cn.footerLink} to='/privatuma-politika'>
        Privātuma politika
      </NavLink>
    </div>
  );
};
