import "./App.css";
import React, { useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { NavBar } from "./components/nav-bar/NavBar";
import { Footer } from "./components/footer/Footer";
import { AppLoader } from "./components/app-loader/AppLoader";
import posthog from "posthog-js";

const Home = lazy(() => import("./pages/home/Home"));
const AboutUs = lazy(() => import("./pages/about-us/AboutUs"));
const Cart = lazy(() => import("./pages/cart/Cart"));
const ShopPageContainer = lazy(() => import("./pages/shop/ShopPageContainer"));
const Contacts = lazy(() => import("./pages/contacts/Contacts"));
const PrivacyPolicy = lazy(() =>
  import("./pages/privacy-policy/PrivacyPolicy")
);
const CookieBanner = lazy(() =>
  import("./components/cookie-banner/CookieBanner")
);
const CheckoutSuccess = lazy(() =>
  import("./pages/checkout-success/CheckoutSuccess")
);
const NotificationModal = lazy(() =>
  import("./components/modals/NotificationModal")
);
const SingleItemPage = lazy(() =>
  import("./pages/single-item-page/SingleItemPage")
);

function App() {
  const products = useSelector((state) => state.products.items);
  const userHasDecided =
    posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing();

  useEffect(() => {
    localStorage.setItem("productCart", JSON.stringify(products));
  }, [products]);

  return (
    <div className='App'>
      <NavBar />
      <Suspense fallback={<AppLoader />}>
        {/* <div className='navGap' /> */}
        <Routes>
          <Route path='/shop' element={<ShopPageContainer />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/' element={<Home />} />
          <Route path='/par-mums' element={<AboutUs />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='shop/shopItem/:itemId' element={<SingleItemPage />} />
          <Route path='/success-page' element={<CheckoutSuccess />} />
          <Route path='/privatuma-politika' element={<PrivacyPolicy />} />
        </Routes>
      </Suspense>
      {!userHasDecided && <CookieBanner />}
      <Footer />
      <NotificationModal />
    </div>
  );
}

export default App;
