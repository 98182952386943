import { createSlice } from "@reduxjs/toolkit";
import { PAYMENTMETHODS } from "../../constants/GeneralConstants";

export const customerDataSlice = createSlice({
  name: "customerData",
  initialState: {
    nameSurname: "",
    email: "",
    phone: "",
    deliveryOption: "",
    deliveryAddress: "",
    isFirm: false,
    firmName: "",
    regNumber: "",
    pvnNumber: "",
    jurAddress: "",
    paymentMethod: PAYMENTMETHODS.card,
    isFormValid: false,
    isLoadingInvoiceSubmit: false,
  },
  reducers: {
    changeCustomerNameSurname: (state, action) => {
      return { ...state, nameSurname: action.payload };
    },
    changeCustomerEmail: (state, action) => {
      return { ...state, email: action.payload };
    },
    changeCustomerDeliveryOption: (state, action) => {
      return { ...state, deliveryOption: action.payload };
    },
    changeCustomerDeliveryAddress: (state, action) => {
      return { ...state, deliveryAddress: action.payload };
    },
    changeCustomerPhone: (state, action) => {
      return { ...state, phone: action.payload };
    },
    changeCustomerIsFirm: (state, action) => {
      state.isFirm = action.payload;
    },
    changeCustomerFirmName: (state, action) => {
      state.firmName = action.payload;
    },
    changeCustomerRegNumber: (state, action) => {
      state.regNumber = action.payload;
    },
    changeCustomerPvnNumber: (state, action) => {
      state.pvnNumber = action.payload;
    },
    changeCustomerJurAddress: (state, action) => {
      state.jurAddress = action.payload;
    },
    changeCustomerPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    changeCustomerIsFormValid: (state, action) => {
      state.isFormValid = action.payload;
    },
    changeCustomerLoadingInvoiceSubmit: (state, action) => {
      state.isLoadingInvoiceSubmit = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeCustomerNameSurname,
  changeCustomerEmail,
  changeCustomerAddress,
  changeCustomerPostcode,
  changeCustomerPhone,
  changeCustomerDeliveryOption,
  changeCustomerDeliveryAddress,
  changeCustomerIsFirm,
  changeCustomerFirmName,
  changeCustomerRegNumber,
  changeCustomerPvnNumber,
  changeCustomerJurAddress,
  changeCustomerPaymentMethod,
  changeCustomerIsFormValid,
  changeCustomerLoadingInvoiceSubmit,
} = customerDataSlice.actions;

export default customerDataSlice.reducer;
