import cn from "./CartSideMenu.module.css";
import { useDispatch, useSelector } from "react-redux";
import { itemAdded, itemRemoved } from "../../features/counter/counterSlice";
import { FaTimes } from "react-icons/fa";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { totalProductPrice } from "../../helpers/TotalPrice";
import { countItems } from "../../helpers/CountItems";

export const CartSideMenu = ({ cart, filteredCart, handleCartClose }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.items);

  const itemCountPrice = (item) => {
    const itemRowPrice = countItems(cart, item.id) * item.price;
    return itemRowPrice.toFixed(2);
  };

  const handleAddItem = (e, item) => {
    e.stopPropagation();
    dispatch(
      itemAdded({
        id: item.id,
        image: item.image,
        name: item.name,
        price: item.price,
        glass_deposit: !!item.glass_deposit,
        category: item.category,
      })
    );
  };

  const handleRemoveItem = (e, item) => {
    e.stopPropagation();
    dispatch(itemRemoved(item.id));
  };

  return (
    <div className={cn.cartSideMenuContainer}>
      <div className={cn.titleRow}>
        <div className={cn.cartTitle}>Iepirkumu Grozs</div>
        <FaTimes
          style={{ cursor: "pointer" }}
          onClick={() => handleCartClose()}
        />
      </div>
      <div className={cn.cartSideMenuItemListContainer}>
        {filteredCart.map((item) => {
          return (
            <div className={cn.cartSideMenuItem} key={item.id}>
              <section>
                <img
                  src={item.image}
                  className={cn.cartSideMenuItemImage}
                  alt='Shopping Item'
                />
              </section>
              <section>
                <div className={cn.cartSideMenuItemName}>{item.name}</div>
                <div className={cn.quantitySelectorRow}>
                  <AiOutlineMinus
                    className={cn.addRemove}
                    onClick={(e) => handleRemoveItem(e, item)}
                  />
                  <div className={cn.itemCount}>
                    {countItems(cart, item.id)}
                  </div>
                  <AiOutlinePlus
                    className={cn.addRemove}
                    onClick={(e) => handleAddItem(e, item)}
                  />
                </div>
              </section>
              <section className={cn.thirdSection}>
                <div className={cn.cartItemPrice}>Cena</div>
                <div className={cn.cartItemSideMenuPriceNumber}>
                  €{itemCountPrice(item)}
                </div>
                {item.glass_deposit && (
                  <div className={cn.depositText}>{`(+€${(
                    countItems(cart, item.id) * 0.1
                  ).toFixed(2)}  depozīts)`}</div>
                )}
              </section>
            </div>
          );
        })}
      </div>
      <div className={cn.totalAmmount}>
        {cart?.length > 0 ? (
          <NavLink to='/cart'>
            <div onClick={() => handleCartClose()}>Apskatīt Grozu</div>
          </NavLink>
        ) : (
          <div>Iepirkumu grozs ir tukšs</div>
        )}
        <div>Kopā: €{totalProductPrice(products)}</div>
      </div>
    </div>
  );
};
