export const PAYMENTMETHODS = {
  card: "card",
  cash: "cash",
  invoice: "invoice",
};

export const NOTIFICATIONTYPES = {
  success: "success",
  error: "error",
};

export const NOTIFICATIONMESSAGES = {
  errorMessage: "Ir notikusi kļūda, atvainojamies par neērtībām!",
  successInvoiceMessage:
    "Jūsu pasūtījums ir veiksmīgi apstrādāts. Pēc brīža Jūs saņemsiet savā e-pastā pasūtījuma rēķinu.",
};

export const DELIVERYOPTIONS = {
  omniva: "Omniva pakomāts",
  selfPickup: "Izņemšu pats",
};

export const ARROWTYPES = {
  rightArrow: "rightArrow",
  leftArrow: "leftArrow",
};

export const BUTTONTYPES = {
  primary: "primary",
  secondary: "secondary",
};

export const translationValuesDesktop = {
  1: 20,
  2: -125,
  3: -270,
  4: -415,
};

export const translationValuesMobile = {
  1: 20,
  2: -110,
  3: -242,
  4: -372,
};
